<template>
  <div class="container">
    <div class="footer-left">
      <img
          src="../assets/img/header/logo.svg"
          alt="LinkingInnovations" class="footer-logo">
      <div class="footer-subscribe">{{ $t('Bottom.subscribeNews') }}</div>
      <div class="footer-left-information" v-if="!showEmailSuccess">
        <a-input class="footer-input" v-model="value" :placeholder="$t('Bottom.enterMailbox')"/>
        <a-button type="primary" class="footer-subscribe-btn" @click="sendEmail">{{ $t('Bottom.subscribe') }}</a-button>
      </div>
      <div class="footer-left-information-success" v-else>
        <div class="footer-left-information-success-div">
          <img src="../assets/img/bottom/subscribe.png" class="subscribe-logo"/>{{ $t('Bottom.thankSubscribe') }}
        </div>
      </div>
      <div class="footer-input-div" v-show="showEmail">
        <a-icon type="exclamation-circle" theme="filled" class="footer-waring"/>
        <span class="footer-waring-text">{{ emailWarning }}</span>
      </div>
    </div>
    <div class="footer-center">
      <ul>
        <li v-for="(item,index) in navLists" :key="index">
          <router-link v-if="index != 4" :to="item.route">{{ item.text }}</router-link>
          <router-link v-if="index==4 && showFreeTrial" target="_blank" :to="item.route">{{ item.text }}</router-link>
        </li>
      </ul>
    </div>
    <div class="footer-right">
      <div class="footer-right-one">{{ $t('Bottom.title') }}</div>
      <div class="footer-right-common" v-for="(item,index) in companyInformation" :key="index">{{ item }}</div>
    </div>
    <div class="footer-right-record">
      <a class="footer-right-record-a" target="_blank" href=" "><img
          src="../assets/img/bottom/publicSecurityRecord.png"/>
        <p class="footer-right-record-public">京公网安备 11030102011223号</p></a>
      <a class="footer-right-record-ip" href="https://beian.miit.gov.cn/">{{ $t('Common.record') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
      showEmailSuccess: false,
      showFreeTrial: true,
      emailWarning: this.$t('Bottom.enterCorrectMailbox'),
      value: '',
      showEmail: false,
      companyInformation: [this.$t('Bottom.telephone'), this.$t('Bottom.email'), this.$t('Bottom.address')],
      navLists: [
        {
          text: this.$t('Common.index'),
          route: "/"
        },
        {
          text: this.$t('Common.productCenter'),
          route: "/ProductCenter"
        },
        {
          text: this.$t('Common.joinUs'),
          route: "/JoinUs"
        },
        {
          text: this.$t('Common.contactUs'),
          route: "/ContactUs"
        },
        {
          text: this.$t('Common.freeTrial'),
          route: "/FreeTrial"
        }
      ]
    }
  },
  created() {
    if (
        window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
      this.showFreeTrial = false;
    }
  },
  methods: {
    sendEmail() {
      let regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!regEmail.test(this.value) && this.value) {
        this.emailWarning = this.$t('Bottom.enterCorrectMailbox');
        this.showEmail = true;
      } else if (!this.value) {
        this.emailWarning = this.$t('Bottom.enterMail');
        this.showEmail = true;
      } else {
        this.showEmail = false;
        this.showEmailSuccess = true;
      }
    }
  }
}
</script>

<style scoped lang="less">
.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 450px;
  background: #010101;
  padding: 0 240px;
}

.footer-logo {
  display: block;
  width: 356px;
  height: 41px;
  margin-bottom: 100px;
}

.footer-left {
  margin-top: 74px;
  display: flex;
  flex-direction: column;
}

.footer-subscribe {
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
  text-align: left;
  margin-bottom: 22px;
}

.footer-input {
  width: 400px;
  height: 56px;
  float: left;
  background: rgba(216, 216, 216, 0.3);
  font-size: 16px;
  border: 0;
}

.footer-subscribe-btn {
  width: 100px;
  height: 56px;
  background: #22DCA1;
  font-size: 20px;
  color: #212121;
}

/deep/ .footer-input-div .anticon svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #FFBC00;
}

.footer-waring-text {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.footer-input-div {
  margin-top: 16px;
  display: flex;
  line-height: 22px;
  text-align: left;
}

ul {
  margin-top: 100px;
}

li {
  line-height: 28px;
  margin-bottom: 20px;
  text-align: left;
}

li a {
  font-size: 16px;
  white-space: nowrap;
}

.footer-right {
  position: relative;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
}

.footer-right-one {
  line-height: 28px;
  margin-top: 154px;
  margin-bottom: 36px;
  text-align: left;
}

.footer-right-common {
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
  line-height: 28px;
}

.footer-right-record {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 34px;
  left: 50%;
  text-align: center;
  transform: translate(-50%,0);
}

.footer-right-record-public {
  height: 20px;
  line-height: 20px;
  margin: 0px 0px 0px 5px;
  color: #939393;
}

.footer-right-record-a {
  display: flex;
  text-decoration: none;
  height: 20px;
  font-size: 16px;
  line-height: 20px;
}

.footer-right-record-ip{
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 24px;
  color: #939393;
}

.footer-center {
  margin-left: 210px;
  margin-right: 140px;
}

.footer-left-information {
  display: flex;
}

.footer-left-information-success {
  width: 500px;
  font-size: 24px;
  color: #FFFFFF;
}

.footer-left-information-success-div {
  width: 300px;
  height: 60px;
  background: #424242;
  border-radius: 4px;
  line-height: 60px;

}

.subscribe-logo {
  display: inline-block;
  width: 50px;
  margin-right: 10px;
}

.footer-center a {
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  text-decoration: none;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 216px;
    background: #010101;
    padding: 0 12px;
  }

  .footer-logo {
    display: block;
    width: 80px;
    height: 16px;
    margin-bottom: 30px;
  }

  .footer-left {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
  }

  .footer-subscribe {
    font-size: 8px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 10px;
    text-align: left;
    margin-bottom: 20px;
  }

  .footer-input {
    width: 80px;
    height: 22px;
    float: left;
    background: rgba(216, 216, 216, 0.3);
    font-size: 8px;
    border: 0;
  }

  .footer-subscribe-btn {
    width: 80px;
    height: 22px;
    background: #22DCA1;
    font-size: 8px;
    margin-top: 4px;
    color: #212121;
  }

  /deep/ .footer-input-div .anticon svg {
    width: 8px;
    height: 8px;
    margin-right: 4px;
    color: #FFBC00;
  }

  .footer-waring-text {
    font-size: 8px;
    line-height: 8px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  .footer-input-div {
    margin-top: 6px;
    display: flex;
    line-height: 11px;
    text-align: left;
  }

  ul {
    margin-top: 64px;
  }

  li {
    line-height: 10px;
    margin-bottom: 8px;
    text-align: left;
  }

  li a {
    font-size: 8px;
    white-space: nowrap;
  }

  .footer-right {
    position: relative;
    font-size: 8px;
    font-weight: 400;
    color: #FFFFFF;
  }

  .footer-right-one {
    margin-top: 64px;
    margin-bottom: 14px;
    text-align: left;
    line-height: 10px;
  }

  .footer-right-common {
    margin-bottom: 10px;
    text-align: left;
    font-size: 8px;
    line-height: 10px;
  }

  .footer-center {
    margin: 0 10px;
  }
  .footer-center a {
    font-size: 8px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    white-space: nowrap;
  }
  .footer-left-information {
    display: flex;
    flex-direction: column;
  }
  .footer-right-record {
    padding: 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    left: 50%;
    text-align: right;
    transform: translate(-50%,0);
  }

  .footer-right-record-public {
    float: left;
    height: 12px;
    line-height: 12px;
    margin: 0px 0px 0px 5px;
    color: #939393;
  }

  .footer-right-record-a {
    display: flex;
    text-decoration: none;
    height: 12px;
    font-size: 8px;
    line-height: 12px;
  }

  .footer-right-record-ip{
    cursor: pointer;
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>