import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            component: () => import('@/views/Index.vue'),
            meta: { navShow: true, title: "首页", index: 0 }
        },
        {
            path: "/ProductCenter",
            component: () => import('@/views/ProductCenter.vue'),
            meta: { navShow: true, title: "产品中心", index: 1 }
        },
        {
            path: "/JoinUs",
            component: () => import('@/views/JoinUs.vue'),
            meta: { navShow: true, title: "加入我们", index: 2 }
        },
        {
            path: "/ContactUs",
            component: () => import('@/views/ContactUs.vue'),
            meta: { navShow: true, title: "联系我们", index: 3 }
        },
        {
            path: "/FreeTrial",
            component: () => import('@/views/FreeTrial.vue'),
            meta: { navShow: false, title: "免费试用", index: 4 }
        },
        {
            path: "/evaluation-form",
            component: () => import('@/views/EvaluationForm.vue'),
            meta: { navShow: true, title: "免费试用", index: 5 }
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});
