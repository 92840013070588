<template>
  <div id="app">
    <Header v-show="$route.meta.navShow" />
    <router-view />
    <Bottom v-show="$route.meta.navShow" />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Bottom from '@/components/Bottom.vue'

export default {
  name: 'App',
  components: {
    Header,
    Bottom
  }
}
</script>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
  background: #111111;
}
</style>
