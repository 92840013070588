<template>
  <div>
    <!-- pc -->
    <div v-if="showFreeTrial">
      <nav class="container" :class="{ 'background-nav': isShowNavBackground }">
        <img src="../assets/img/header/logo.svg" alt="LinkingInnovations" class="logo">
        <div id="warp" class="box">
          <div v-for="(item, index) in navLists" :key="index" class="nav">
            <router-link :class="{ 'active-text': index === $route.meta.index }" :to="item.route"
              class="router-link-text">
              {{ item.text }}
            </router-link>
            <span :class="{ active: index === $route.meta.index }"></span>
          </div>
        </div>
        <a-button type="primary" class="FreeTrial" @click="toFreeTrial">{{ $t('Common.freeTrial') }}
        </a-button>
      </nav>
    </div>
    <!-- 移动 -->
    <div v-else>
      <div class="m-menu" v-show="isMenu" @click="showNav">
        <div class="m-menu-long" />
        <div class="m-menu-shart" />
        <div class="m-menu-long" />
      </div>
      <div v-show="!isMenu">
        <nav class="m-container">
          <div id="m-warp" class="m-box">
            <div class="m-nav">
              <img src="../assets/img/header/arrow.svg" alt="LinkingInnovations" class="m-arrow" @click="hiddenNav" />
            </div>
            <div v-for="(item, index) in navLists" :key="index" class="m-nav" @click="hiddenNav"
              :class="{ 'm-nav-active': index === $route.meta.index }">
              <span class="m-active-default" :class="{ 'm-active': index === $route.meta.index }"></span>
              <router-link :class="{ 'm-active-text': index === $route.meta.index }" :to="item.route"
                class="m-router-link-text">
                {{ item.text }}
              </router-link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isShowNavBackground: false,
      lang: 'zh',
      isMenu: true,
      showFreeTrial: true,
      navLists: [
        {
          text: this.$t('Common.index'),
          route: "/"
        },
        {
          text: this.$t('Common.productCenter'),
          route: "/ProductCenter"
        },
        {
          text: this.$t('Common.joinUs'),
          route: "/JoinUs"
        },
        {
          text: this.$t('Common.contactUs'),
          route: "/ContactUs"
        }
      ]
    }
  },
  created() {
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.showFreeTrial = false;
    }
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    showNav() {
      this.isMenu = false;
    },
    hiddenNav() {
      this.isMenu = true;
    },
    handleScroll() {
      window.addEventListener('scroll', () => {
        this.isShowNavBackground = window.scrollY > 50
      })
    },
    toFreeTrial() {
      let routeUrl = this.$router.resolve({
        path: "/FreeTrial"
      });
      window.open(routeUrl.href, '_blank');
    },
    //切换语言
    changeLangEvent() {
      if (this.lang === 'zh') {
        this.lang = 'en';
        this.$i18n.locale = this.lang;
      } else {
        this.lang = 'zh';
        this.$i18n.locale = this.lang;
      }
    },
  }
}
</script>

<style scoped lang="less">
.background-nav {
  background: #010101 !important;
  transition: all 0.8s;
}

.container {
  position: fixed;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 80px;
  z-index: 999;
  background: transparent;
  transition: all 0.8s;
}

.logo {
  width: 356px;
  height: 40px;
}

.box {
  display: flex;
  justify-content: space-around;
  background: transparent;
}

.nav {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  align-items: center;
  margin-right: 124px;
}

.nav:nth-child(4) {
  margin-right: 0;
}

.active {
  display: inline-block;
  width: 25px;
  height: 1px;
  background: #22DCA1;
  border: 1px solid #22DCA1;
  margin-top: 4px;
}

.active-text {
  color: #22DCA1 !important;
}

.FreeTrial {
  padding: 0;
  width: 130px;
  height: 44px;
  font-size: 18px;
  color: #212121;
}

.router-link-text {
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  text-decoration: none;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .m-container {
    position: fixed;
    width: 65%;
    height: 100%;
    z-index: 999;
    background: #222222;
  }

  .m-logo {
    display: none;
  }

  .m-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .m-nav {
    width: 100%;
    height: 60px;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    align-items: center;
  }

  .m-nav-active {
    background: #333333;
  }

  .m-active-default {
    display: inline-block;
    width: 4px;
    height: 100%;
    background: #444444;
  }

  .m-active {
    background: #22DCA1;
  }

  .m-FreeTrial {
    width: 50px;
    height: 16px;
    font-size: 10px;
    color: #212121;
    display: none;
  }

  .m-active-text {
    font-weight: 600;
  }

  .m-router-link-text {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 10px;
    text-align: left;
    align-items: center;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 20px;
    white-space: nowrap;
  }

  .m-menu {
    z-index: 999;
    position: fixed;
    left: 20px;
    top: 20px;
    padding-top: 5px;
    width: 20px;
    height: 20px;
    background: #2c2c2c;
    border-radius: 1px;
  }


  .m-menu-long {
    margin: 0 auto;
    width: 10px;
    height: 1px;
    background-color: #FFFFFF;
  }

  .m-menu-shart {
    margin: 3px 5px;
    width: 6px;
    height: 1px;
    background-color: #FFFFFF;
  }

  .m-arrow {
    width: 24px;
    stroke: #ffffff;
    position: absolute;
    right: 10px;
  }
}
</style>