import Vue from 'vue'
import router from "./router";
import {Button, Icon, Input, Carousel, FormModel, Select, Progress, Upload, DatePicker,Modal,message,Tag,Checkbox} from 'ant-design-vue'
import App from './App.vue'
import './libs/rem.js';
import './assets/global.css';
//引用i18n
import VueI18n from 'vue-i18n';
import uploader from 'vue-simple-uploader'
Vue.use(uploader)

Vue.component(Button.name, Button)
Vue.component(Button.Group.name, Button.Group)
Vue.component(Icon.name, Icon)
Vue.component(Input.name, Input)
Vue.component(Input.TextArea.name, Input.TextArea)
Vue.component(Carousel.name, Carousel)
Vue.component(FormModel.name, FormModel)
Vue.component(FormModel.Item.name, FormModel.Item)
Vue.component(Select.name, Select)
Vue.component(Select.Option.name, Select.Option)
Vue.component(Progress.name, Progress)
Vue.component(Upload.name, Upload)
Vue.component(Upload.Dragger.name, Upload.Dragger)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Tag.name, Tag)
Vue.component(Tag.CheckableTag.name, Tag.CheckableTag)
Vue.component(Checkbox.name, Checkbox)
Vue.use(message)
Vue.use(Modal)
Vue.use(VueI18n) // 通过插件的形式挂载，通过全局方法 Vue.use() 使用插件
const i18n = new VueI18n({
    locale: 'zh', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        'zh': require('./VueI18n/language-zh.json'),
        'en': require('./VueI18n/language-en.json')
    }
});
Vue.config.productionTip = false
new Vue({
    render: h => h(App),
    router,
    i18n
}).$mount('#app')
